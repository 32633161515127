// Expanded project card logic for Project list page
/* eslint no-undef: 0 */

import { s__ } from '~/locale';

const getStartingPoint = (cardNumber) => {
  let number = cardNumber;

  if (number < 4) return 1;

  let startingPoint = 1;

  while (number > 3) {
    number -= 3;
    startingPoint += 1;
  }

  return startingPoint;
};

const shrinkExpandedCard = () => {
  const previousExpandedCard = $('#expanded-view');

  if (previousExpandedCard != null) {
    previousExpandedCard.addClass('default-card-width').removeClass('extended-card-width')
    const quickViewCard = previousExpandedCard.find('.quick-view');
    const defaultViewCard = previousExpandedCard.find('.default-view');

    previousExpandedCard.removeAttr('id');
    previousExpandedCard.css({ 'grid-area': '' });

    if (quickViewCard.is(':visible')) {
      quickViewCard.hide();
      defaultViewCard.removeClass('hidden').show();
    } else {
      quickViewCard.show();
      defaultViewCard.hide();
    }
  }
};

const expandCard = (currentTarget) => {
  shrinkExpandedCard();

  const { cardNumber } = currentTarget.data();
  const startingPoint = getStartingPoint(cardNumber);

  if (cardNumber % 3 !== 0) {
    currentTarget.css({
      'grid-column-start': '1',
      'grid-column-end': '3',
    });
  } else {
    currentTarget.css({
      'grid-column-start': '2',
      'grid-column-end': '4',
    });
  }

  currentTarget.css({
    'grid-row-start': `${startingPoint}`,
    'grid-row-end': `${startingPoint + 2}`,
  });
  currentTarget.attr('id', 'expanded-view');

  const defaultViewCard = currentTarget.find('.default-view');
  const quickViewCard = currentTarget.find('.quick-view');

  if (defaultViewCard.is(':visible')) {
    defaultViewCard.hide();
    quickViewCard.removeClass('hidden').show();
  } else {
    defaultViewCard.show();
    quickViewCard.removeClass('hidden').hide();
  }
};

$(document).on('click', '.project-card > .card-body', ({ target }) => {
  location.href = target.children[0]?.href;
});

$(document).on('click', '.project-details-btn', ({ target }) => {
  const currentTarget = $(target).parents().closest('.full-card');
  if ($(target).text() === s__('Show Details')) {
    currentTarget.removeClass('project-card-size').addClass('extended-card-width')
    expandCard(currentTarget);
  } else {
    shrinkExpandedCard();
  };
});

// Project Sidebar Menu Items
$(() => $('.sidebar-top-level-items li.active .sidebar-caret-right').toggleClass('gl-rotate-90'));

$('li.sidebar-menu-item > a, .toggle-sidebar-button').hover(() => {
  if ($('.nav-sidebar-inner-scroll').css("overflow-x") === 'hidden') {
    setTimeout(() => {
      $('div.tooltip').css("visibility", "visible");
    }, 550);

    return;
  }

  $('div.tooltip').css("visibility", "hidden");
});

$(() => {
  const projectNavSidebar = $('.nav-sidebar');
  const contextHeaderLink = $('.context-header > a');
  const contextHeaderLinkSpan = $('.context-header > a span');
  const sidebarMenuItemLink = 'li.sidebar-menu-item > a';
  const projectSettingsTitle = '.project-settings-title';

  const toggleSidebarCollapsedClasses = () => {
    contextHeaderLink.addClass('collapsed-desktop')
    contextHeaderLinkSpan.removeClass('s40');
    $(`${sidebarMenuItemLink}`).css('justify-content','center')
    $(`${sidebarMenuItemLink} .sidebar-caret-right`).hide();
    $(`${sidebarMenuItemLink} span.collapsed-badge-pill`).show();
    $(`${projectSettingsTitle} > span`).hide();
    $(`${projectSettingsTitle} > .divider`).show();
    $('li.context-header > a .sidebar-header-caret-icon').hide();
  }

  const toggleSidebarDesktopClasses = () => {
    contextHeaderLink.removeClass('collapsed-desktop')
    contextHeaderLinkSpan.addClass('s40');
    $(`${sidebarMenuItemLink} .sidebar-caret-right`).show();
    $(`${sidebarMenuItemLink} span.collapsed-badge-pill`).hide();
    $(`${projectSettingsTitle} > .divider`).hide();
    $(`${projectSettingsTitle} > span`).show();
    $('li.context-header > a .sidebar-header-caret-icon').show();
  }

  if (projectNavSidebar.hasClass('js-sidebar-collapsed')) {
    toggleSidebarCollapsedClasses();
  } else {
    $(`${projectSettingsTitle} > .divider`).hide();
    $(`${sidebarMenuItemLink} span.collapsed-badge-pill`).hide();
  }

  $('.toggle-sidebar-button').click(() => {
    if (projectNavSidebar.hasClass('js-sidebar-collapsed')) {
      toggleSidebarCollapsedClasses();
    } else {
      toggleSidebarDesktopClasses();
    }
  });
});
