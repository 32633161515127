// TODO: With the combined_menu feature flag removed, there's likely a better
// way to slice up the async import (i.e., include trigger in main bundle, but
// async import subviews. Don't do this at the cost of UX).
// See https://gitlab.com/gitlab-org/gitlab/-/issues/336042
const importModule = () => import(/* webpackChunkName: 'top_nav' */ './mount');

const tryMountTopNav = async () => {
  const topNavElement = document.getElementById('js-top-nav');
  const sideNavElement = document.getElementById('js-side-nav');
  const inviteCollaboratorNavElement = document.getElementById('invite-collaborator');

  if (!inviteCollaboratorNavElement) {
    return;
  }

  const { mountTopNav, mountInviteFriendButton, mountSideNav } = await importModule();

  mountTopNav(topNavElement);
  sideNavElement && mountSideNav(sideNavElement);
  mountInviteFriendButton(inviteCollaboratorNavElement);
};

const tryMountTopNavResponsive = async () => {
  const el = document.getElementById('js-top-nav-responsive');

  if (!el) {
    return;
  }

  const { mountTopNavResponsive } = await importModule();

  mountTopNavResponsive(el);
};

export const initTopNav = async () => Promise.all([tryMountTopNav(), tryMountTopNavResponsive()]);
