$('.select-all-locked-files').change((event) => {
  $('.unlock-file-checkbox').prop('checked', $(event.target).is(':checked'))
});

$('.select-all-locked-files, .unlock-file-checkbox').change(() => {
  const numberOfChecked = $('input.unlock-file-checkbox:checked').length;
  const unlockSelectedButton = $("#unlock-selected-files-btn");

  if (numberOfChecked > 1) {
    unlockSelectedButton.removeClass('disabled')
    unlockSelectedButton.addClass('btn-confirm')
  } else {
    unlockSelectedButton.addClass('disabled')
    unlockSelectedButton.removeClass('btn-confirm')
  }
});
