import { __ } from '~/locale';

export const COMMUNITY_POST_VISIBILITY_PRIVATE = 'private';
export const COMMUNITY_POST_VISIBILITY_INTERNAL = 'internal';
export const COMMUNITY_POST_VISIBILITY_PUBLIC = 'public';

export const COMMUNITY_POST_VISIBILITY = {
  [COMMUNITY_POST_VISIBILITY_PRIVATE]: {
    label: __('Private'),
    icon: 'lock',
    description: __('The community post is visible only to me.'),
    description_project: __('The community post is visible only to project members.'),
  },
  [COMMUNITY_POST_VISIBILITY_INTERNAL]: {
    label: __('Internal'),
    icon: 'shield',
    description: __('The community post is visible to any logged in user except external users.'),
  },
  [COMMUNITY_POST_VISIBILITY_PUBLIC]: {
    label: __('Public'),
    icon: 'earth',
    description: __('The community post can be accessed without any authentication.'),
  },
};

export const COMMUNITY_POST_CREATE_MUTATION_ERROR = __("Can't create community post: %{err}");
export const COMMUNITY_POST_UPDATE_MUTATION_ERROR = __("Can't update community post: %{err}");

export const COMMUNITY_POST_LEVELS_MAP = {
  0: COMMUNITY_POST_VISIBILITY_PRIVATE,
  10: COMMUNITY_POST_VISIBILITY_INTERNAL,
  20: COMMUNITY_POST_VISIBILITY_PUBLIC,
};
export const COMMUNITY_POST_LEVELS_RESTRICTED = __(
  'Other visibility settings have been disabled by the administrator.',
);
export const COMMUNITY_POST_LEVELS_DISABLED = __(
  'Visibility settings have been disabled by the administrator.',
);
